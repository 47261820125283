<template>
  <!-- youtube player -->
  <YouTubePlayer
    v-if="url"
    :title="title"
    :url="url"
    @close="close"
  />
</template>

<script>
import { YouTubePlayer } from '@seliaco/red-panda'
export default {
  name: 'YoutubePlayer',
  components: { YouTubePlayer },
  data () {
    return {
      title: null,
      url: null,
      event: null
    }
  },
  created () {
    window.addEventListener('message', (event) => {
      this.event = event
      this.url = event.data.url
      this.title = event.data.title
    })
  },
  methods: {
    close () {
      this.url = null
      this.event.source.postMessage('close', this.event.origin)
    }
  }
}
</script>
